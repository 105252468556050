export const locale = {
    lang: 'en',
    data: {
        NAV: {
            HOME: 'home',
            MYCOURSES: 'My Courses',
            MYCERTIFICATES: 'My Certificates',
            MESSAGES: 'Messages',
            COURSES: 'Courses',
            COURSE: 'Course',
            CATEGORIES: 'Categories',
            LANGUAGES: 'Languages',
            CURRENCIES: 'Currencies',
            GENERAL: 'General',
            ADMIN: 'Admin',
            ENABLE_EDITION: 'Enable Edition',
            SETTINGS: 'Settings',
            INSTITUTION: 'Institutions',
            ROLES: 'Roles',
            USERS: 'Users',
            CONTACTS: 'Contacts',
            WELCOMECONTENT: 'Home content',
            RRHH: 'Human resources',
            LEGALDOCUMENTS: 'Legal documents',
            GENERALINFO: 'General info',
            TEACHERSMANAGEMENT: 'Teachers management',
            OURCOURSES: 'Differentiators',
            GENERALCONFIG: 'General Configuration',
            DEAN: 'DEAN',
            SLIDERS: 'Sliders',
            VIDEO: 'Video',
            SECTIONS: 'Sections',
            TESTMAIL: 'Test mail',
            WHATSAPP: 'Send from WhatsApp',
            ADS: 'Ads',
            GRADES: 'Grades',
            university: 'University',
            procedure: 'Procedure',
            school_control: 'School control',
            indicators: 'Indicators',
            STUDYPLAN: 'Study plan',
            CAREER: 'Career',
            DISCIPLINE: 'Discipline',
            LEVEL: 'Levels',
            STAGE: 'Module',
            SUBJECT: 'Subject',
            SALES: 'Sales',
            COUPONS: 'Coupons',
            ENROLMENTANNOUNCEMENT: 'Enrolment announcement',
            CAMPAIGN: 'Campaigns',
            COURSEANNOUNCEMENT: 'Course announcement',
            preenrolment: 'Pre enrolment',
            incomes: 'Incomes',
            enrolment: 'Enrolment',
            enrolmentFail: 'Failed enrolment',
            offers: 'Academics offers',
            students: 'Students',
            debtor: 'Import debtors',
            GENERATIONS: 'Generations',
            GROUP: 'Group',
            courseAccess: 'Course access',
            forumAccess: 'Forum access',
            evaluationAccess: 'Evaluation access',
            feedbackAccess: 'Feedback access',
            reports: 'Reports',
            coffeeshop: 'Coffee shop',
            content: 'Content',
            syncLesson: 'Sync lesson',
            neuuniWatch: 'Neuuni Play',
            series: 'Series',
            tendencies: 'Tendencies',
            moreViews: 'More Views',
            videos: 'Videos',
            indicator: 'Indicators',
            indicatorKDUnit: 'CADE Indicator by Topic',
            indicatorKDCourse: 'CADE indicator by Course',
            indicatorKDMentor: 'CADE indicator by Mentor',
            sendWhatsappText: 'Sending Text',
            migrar: 'Migrate',
            migrarUsuario: 'User Migration',
            resultExport: 'Export Result'
        }
    }
};
