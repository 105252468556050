import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Course} from '../../models/course';
import {Injectable} from '@angular/core';
import {CourseRemoteService} from '../remote-services/course.remote.service';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CourseInfo} from '../../models/course-info';
import {SecurityService} from '../../../core/services/security.service';
import {FuseNavigationService} from '../../../../../@fuse/components/navigation/navigation.service';
import {UnitRemoteService} from '../remote-services/unit.remote.service';
import * as _ from 'lodash';
import {CourseRole} from '../../models/course-role';
import {UserAccessRemoteService} from '../remote-services/user-access.remote.service';
import {CourseMenuService} from '../course-menu.service';
import {DeanInfoRemoteService} from '../../../general-config/services/dean-info.remote.service';
import {ReportsRemoteService} from '../../../reports/remote-services/reports.remote.service';
import {CourseStudentRemoteService} from "../remote-services/course-student-remote.service";

interface HideTitleExamResponse {
    titleExamHideForStudent: boolean;

}

@Injectable()
export class CourseResolverService implements Resolve<CourseInfo> {
    courseId: string;
    courseAnnouncementId: string;
    contextRole: string;
    courseRole: CourseRole;
    courseInfo: CourseInfo;
    courseInfo$: BehaviorSubject<CourseInfo>;
    isSupervisorTeacher = false;
    isStudent = false;
    isTeacher = false;
    courseShowGenral: any;
    titleExamHideForStudent: any;

    constructor(private courseRemoteService: CourseRemoteService, private unitRemoteService: UnitRemoteService,
                private courseStudentRemoteService: CourseStudentRemoteService,
                private router: Router, private _fuseNavigationService: FuseNavigationService,
                private _courseMenuService: CourseMenuService,
                private _userAccessRemoteService: UserAccessRemoteService,
                private _reportsRemoteService: ReportsRemoteService,
                private deanRemoteService: DeanInfoRemoteService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CourseInfo> {
        this.courseId = (route.params.id != null) ? route.params.id : route.params.courseId;
        this.courseAnnouncementId = route.params.courseAnnouncementId;

        if (this.courseInfo$) {
            this.courseInfo$.complete();
        }
        this.courseInfo = null;
        this.courseInfo$ = new BehaviorSubject(this.courseInfo);
        this.isSupervisorTeacher = SecurityService.getUser().roles.includes(SecurityService.ROLE_SUPERVISOR_TEACHER);
        this.contextRole = (this.isSupervisorTeacher) ? 'teacher' : SecurityService.getCurrentContextFromRole();

        const contextRole = SecurityService.getCurrentContextFromRole();
        this.isStudent = contextRole === 'student';

        this.fetchTeachers();
        this.fetchDean();

        const endpoints = [
            this.courseRemoteService.get(this.courseId, true, this.courseAnnouncementId),
            this.courseRemoteService.getMemberCourseRole(this.courseId, SecurityService.getUser().id),
            this.unitRemoteService.getUnitsStructureByCourse(this.courseId, this.courseAnnouncementId),
            this.hideTitleExam(),

        ];

        return forkJoin(endpoints).pipe(
            map(data => {
                console.log('Valode del data :', data);
                this.courseRole = data[1];
                if (this.courseRole === CourseRole.STUDENT) {
                    this._userAccessRemoteService.updateCourse(this.courseId, this.courseAnnouncementId).subscribe();
                }
                if (!this.courseInfo) {
                    this.courseInfo = new CourseInfo();
                }
                this.courseInfo.course = data[0] as Course;
                this.courseInfo.courseRole = this.courseRole;
                this.courseInfo.unitsInfo = data[2] as any[];
                this.courseInfo.courseAnnouncementId = this.courseAnnouncementId;
                const examShow = data[3];
                console.log('Valor de examShow', examShow);
                this._courseMenuService.setMenu(this.courseInfo, this.contextRole, examShow);
                this.courseInfo$.next(this.courseInfo);
                // console.log(this.courseInfo$);
                if (this.courseRole === 'Teacher') {
                    this.updateTeacherAccessToCourse();
                }
                return _.cloneDeep(this.courseInfo);
            })
        );

    }

    async getCourseData(): Promise<Course> {
        const courseInfo = this.courseInfo$.getValue();
        courseInfo.courseAnnouncementId = this.courseAnnouncementId;
        courseInfo.course = await this.courseRemoteService.get(courseInfo.course.id).toPromise();
        courseInfo.unitsInfo = await this.unitRemoteService.getUnitsStructureByCourse(courseInfo.course.id, this.courseAnnouncementId).toPromise();
        this.courseInfo$.next(courseInfo);
        this._fuseNavigationService.removeNavigationItem('currentCourse');
        const examShow = this.hideTitleExam();
        this._courseMenuService.setMenu(courseInfo, this.contextRole, examShow, false);
        return courseInfo.course;
    }


    fetchTeachers(): void {
        this.courseRemoteService.getTeachers(this.courseId, this.courseAnnouncementId).subscribe(teachers => {
            if (!this.courseInfo) {
                this.courseInfo = new CourseInfo();
            }
            this.courseInfo.teachers = teachers;
            this.courseInfo$.next(this.courseInfo);
        });
    }

    fetchDean(): void {
        this.deanRemoteService.get().subscribe(dean => {
            if (!this.courseInfo) {
                this.courseInfo = new CourseInfo();
            }
            this.courseInfo.dean = dean;
            this.courseInfo$.next(this.courseInfo);
        });
    }

    destroy(): void {
        this.courseInfo$.complete();
        this._courseMenuService.destroy();
        // this.courseNotificationService.destroy();
    }

    removeNavigation(): void {
        this._fuseNavigationService.removeNavigationItem('currentCourse');
    }

    private async updateTeacherAccessToCourse(): Promise<void> {
        const data = {
            courseId: this.courseId,
            type: 'ACCESS_COURSE'
        };
        await this._reportsRemoteService.saveTeacherAccessToCourse(data).toPromise();
    }

     hideTitleExam(): Observable<boolean> {

         if (this.courseId != null) {
             if (this.isStudent) {
                 let courseannouncement = this.courseAnnouncementId;
                 if (this.courseAnnouncementId === undefined){
                     courseannouncement = 'Undefined';
                 }
                 return this.courseStudentRemoteService.hideTitleExamOwnedCourses(this.courseId, courseannouncement).pipe(
                     map((response: HideTitleExamResponse) => response.titleExamHideForStudent)
                 );
             } else if (!this.isStudent) {
                 return this.unitRemoteService.getByCourseIdContainsOneTitleExamOne(this.courseId).pipe(
                     map(() => true)
                 );
             }
         }

         return of(false);
     }
}
