import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppUtils} from "@quiox/global/helpers/app.utils";

@Injectable({
    providedIn: 'root'
})
export class SecurityService {
    static readonly ROLE_MASTER = 'ROLE_MASTER';
    static readonly ROLE_GUEST = 'Guest';
    static readonly ROLE_TEACHER = 'Teacher';
    static readonly ROLE_SUPERVISOR_TEACHER = 'SupervisorTeacher';
    static readonly ROLE_STUDENT = 'Student';
    static readonly ROLE_ADMIN = 'Admin';
    static readonly ROLE_MANAGER = 'Manager';
    static readonly ROLE_CONTACTS_MANAGER = 'ContactsManager';
    static readonly ROLE_SEO_MANAGER = 'SeoManager';
    static readonly ROLE_MARKETING = 'Marketing';
    static readonly ROLE_HUMAN_RESOURCES = 'HumanResources';
    static readonly ROLE_FINANCE = 'Finance';
    static readonly ROLE_SUPERVISOR = 'Supervisor';
    static readonly ROLE_SCHOOL_CONTROL = 'SchoolControl';
    static readonly ROLE_SALES_ASSISTANT = 'SalesAssistant';
    static readonly ROLE_SALES_MANAGER = 'SalesManager';
    static readonly ROLE_SYSTEM = 'System';
    static readonly ROLE_MODERATOR = 'Moderator';
    static readonly ROLE_MEDIA_CREATOR = 'MediaCreator';
    static token$: BehaviorSubject<any>;

    private readonly codeVerifierKey = 'OAuth2CodeFlowPkceCodeVerifier';
    private readonly tokenKey = 'securityToken';
    private readonly userKey = 'currentUser';
    private readonly userHomeKey = 'userHome';
    private readonly couponCodeKey = 'couponCode';
    private readonly couponInfoKey = 'couponInfo';
    // private static user: any;
    private static user$: BehaviorSubject<any>;

    constructor(private storageService: StorageService) {
        SecurityService.user$ = new BehaviorSubject(this.getUser());
        SecurityService.token$ = new BehaviorSubject(this.getSecurityToken());
    }

    static getUser(): any {
        return SecurityService.user$.getValue();        
    }

    static userInfo$(): Observable<any> {
        return SecurityService.user$;
    }

   public setSecurityToken(token: any): void {
        // const expireDate = new Date().getTime() + (1000 * token.expires_in);

        this.storageService.set(this.tokenKey, token.access_token);
        SecurityService.token$.next(token.access_token);

        localStorage.refreshToken = token?.refresh_token;
        const interval: number = (token?.expires_in - 60) * 1000;
        localStorage.tokenRefreshAt = interval;
        const currTime = new Date().getTime();
        localStorage.tokenExpireAt = currTime + interval;
        localStorage.tokenExpireAtReal = currTime + token.expires_in * 1000;
        // this.storageService.set(this.tokenKey, token);
    }

    getSecurityToken(): string {
        // return AppUtils.currenAccesToken();
        return this.storageService.get(this.tokenKey);
    }

    private getUser(): any {
       return this.storageService.getObject(this.userKey);       
    }

    setUser(user: any): void {
        console.log('Entro al setUser', user);
        this.storageService.setObject(this.userKey, user);
        //SecurityService.user = user;        
        SecurityService.user$.next(user);
    }

    updateUser(userInfo: any): void {
        const temp = {...SecurityService.user$.getValue(), ...userInfo};
        // const temp = {...SecurityService.user, ...userInfo};
        this.setUser(temp);
    }

    updateUserHome(url: string): void {
        this.storageService.set(this.userHomeKey, url);
    }

    deleteFromStorage(): void {
        this.storageService.delete(this.tokenKey);
        this.storageService.delete(this.userKey);
        this.storageService.delete(this.userHomeKey);
        this.storageService.delete(this.couponCodeKey);
        this.storageService.delete(this.couponInfoKey);
        this.storageService.delete('myCareers');
        this.storageService.delete('myPreEnrollments');

        SecurityService.user$.next(null);
    }

    static getCurrentContextFromRole(): string {
        let role = '';
        const profile = SecurityService.getUser().profile;
        if (profile !== undefined) {
            role = profile;
        } else if (SecurityService.getUser().roles.includes('Teacher')) {
            role = 'teacher';
        } else if (SecurityService.getUser().roles.includes('Student')) {
            role = 'student';
        } else {
            role = 'manager';
        }
        return role;
    }

    get codeVerifier(): string {
        return this.storageService.get(this.codeVerifierKey);
    }

    set codeVerifier(code) {
        this.storageService.set(this.codeVerifierKey, code);
    }

    get userHome(): string {
        return this.storageService.get(this.userHomeKey);
    }

}
