import {Platform} from '@angular/cdk/platform';
import {DOCUMENT, Location, registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es-US';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {locale as navigationEnglish} from 'app/navigation/i18n/en';
import {locale as navigationSpanish} from 'app/navigation/i18n/es';
import {navigation} from 'app/navigation/navigation';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LangCurrencyService} from './main/core-common/services/lang-currency.service';
import {StorageService} from './main/core/services/storage.service';
import {NavigationEnd, Router} from '@angular/router';
import {AppConfigService} from './main/core/services/app-config.service';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {FNavigationService} from './main/core/services/f-navigation.service';
import {SecurityService} from './main/core/services/security.service';
import {CareerMenuService} from './main/core/services/career-menu.service';
import {BroadcastChannelService} from './main/core/services/broadcast-channel.service';
import {HttpClient} from "@angular/common/http";
import {AppUtils} from "@quiox/global/helpers/app.utils";


registerLocaleData(localeEs);

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     * @param langCurrencyService
     * @param storageService
     * @param router
     * @param appConfigService
     * @param gtmService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private langCurrencyService: LangCurrencyService,
        private storageService: StorageService,
        private router: Router,
        private appConfigService: AppConfigService,
        private gtmService: GoogleTagManagerService,
        private fNavigationService: FNavigationService,
        private location: Location,
        private securityService: SecurityService,
        private careerMenuService: CareerMenuService,
        private broadcastChannelService: BroadcastChannelService,
        private _http: HttpClient
    ) {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['es']); // en

        // Set the default language
        this._translateService.setDefaultLang('es');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationSpanish);

        // Use a language
        this._translateService.use('es');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         *
         */

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.

        // setTimeout(() => {
        //     this._translateService.setDefaultLang('en');
        //     this._translateService.setDefaultLang('es');
        // });

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // App custom code
        const urlSearchParams = new URLSearchParams(window.location.search);
        // this.langCurrencyService.setCurrent(urlSearchParams.get('locale'));
        // SET MXN DEFAULT
        this.langCurrencyService.setCurrent('mx');

        this.storageService.setObject('locale', this.langCurrencyService.getCurrent());
        this.setupAnalitics();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);

                // PATCH DUE TO TRANSLATION LIBRARY BUG
                if (!config.layout.navbar.hidden) {

                    setTimeout(() => {
                        this._translateService.setDefaultLang('en');
                        this._translateService.setDefaultLang('es');
                    });

                }

                this.runInitApp();

            });

        console.log('version: 1.0.0 (14-08-2024.4)');

    }

    private async runInitApp(): Promise<void> {
        const currUrl = this.location.path();
        const url = new URL(window.location.href);
        let profileParam = url.searchParams.get('profile');

        if (profileParam) {
            profileParam = profileParam.toLowerCase();
            this.securityService.updateUser({profile: profileParam});
            this.broadcastChannelService.messageBroadcast({profile_changed: profileParam});
        }

        // This is meant for menu consistency on page reload
        if (SecurityService.getUser()) {
            setTimeout(async () => {
                const executed = await this.careerMenuService.getCareers();
                if (executed) {
                    await this.careerMenuService.getPreEnrollments();
                }
                this.broadcastChannelService.init();
                try {
                    console.log('Entro a verificar external 1');
                    const externalUidUser: any = await this._http.get('api/users/externalUid').toPromise();
                } catch (error) {
                    console.error('Usuario no puede ser procesado, error de autenticación.', error);

                    alert('Usuario no puede ser procesado, error de autenticación');
                    AppUtils.logout();
                }

                this.fNavigationService.determineUserHome();
            });
        }
    }

    private setupAnalitics(): void {
        // console.log(this.appConfigService.getAppConfig().disableGoogleAnalitics);
        if (this.appConfigService.getAppConfig().disableGoogleAnalitics === false) {
            this.router.events.forEach(item => {
                if (item instanceof NavigationEnd) {
                    if (item.url.indexOf('payment') > -1){
                        const gtmTag = {
                            event: 'page',
                            pageName: item.url
                        };
                        this.gtmService.pushTag(gtmTag);
                    }
                }
            });
            // this.gtmService.addGtmToDom();
        }}

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

}
