import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UnauthorizedGuardService } from './main/core/auth/service/unauthorized-guard.service';
import { RootGuard } from './main/core/guard/root.guard';
import { LoginGuard } from './main/auth-cognito/call-back-cognito/login.guard';
import { CallbackGuard } from './main/auth-cognito/call-back-cognito/callback.guard';
import { AuthGuard } from './main/core/guard/auth.guard';


const routes: Routes = [
    // {path: '', redirectTo: '/auth/success', pathMatch: 'full', canActivate: [AuthGuard]},
    { path: '', redirectTo: '/login', pathMatch: 'full'},
    { path: 'callback', canActivate: [CallbackGuard], children: []},
    { path: 'login', canActivate: [LoginGuard], children: []},
    { path: 'unauthorized', canActivate: [UnauthorizedGuardService], children: [] },   
    {
        path: 'courses',
        loadChildren: () => import('./main/course/course.module').then(m => m.CourseModule)
    },
    /*{
        path: 'courses/message',
        loadChildren: () => import('./main/chat/chat.module').then(m => m.ChatModule)
    },*/
    {
        path: 'general',
        loadChildren: () => import('./main/general/general.module').then(m => m.GeneralModule)
    },

    {
        path: 'test',
        loadChildren: () => import('./main/test/test.module').then(m => m.TestModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./main/core/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'payment',
        loadChildren: () => import('./main/payment/payment.module').then(m => m.PaymentModule)
    },
    {
        path: 'welcome-management',
        loadChildren: () => import('./main/welcome/welcome/welcome.module').then(m => m.WelcomeModule)
    },
    {
        path: 'human-resources',
        loadChildren: () => import('./main/human-resources/human-resources.module').then(m => m.HumanResourcesModule)
    },
    {
        path: 'user-report',
        loadChildren: () => import('./main/user-report/user-report.module').then(m => m.UserReportModule)
    },
    {
        path: 'general-config',
        loadChildren: () => import('./main/general-config/general-config.module').then(m => m.GeneralConfigModule)
    },
    {
        path: 'content-sync-lesson',
        loadChildren: () => import('./main/content-sync-lesson/content-sync-lesson.module').then(m => m.ContentSyncLessonModule)
    },
    {
        path: 'university',
        loadChildren: () => import('./main/university/university.module').then(m => m.UniversityModule)
    },
    {
        path: 'coupon',
        loadChildren: () => import('./main/sales/sales.module').then(m => m.SalesModule)
    },
    {
        path: 'users',
        loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'activate',
        loadChildren: () => import('./main/activation/activation.module').then(m => m.ActivationModule)
    },
    {
        path: 'report',
        loadChildren: () => import('./main/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'general-forum',
        loadChildren: () => import('./main/general-forum/general-forum.module').then(m => m.GeneralForumModule)
    },
    {
        path: 'neuuni-watch',
        loadChildren: () => import('./main/neuuni-watch/neuuni-watch.module').then(m => m.NeuuniWatchModule)
    },
    {
        path: 'neuuni-watch-moderator',
        loadChildren: () => import('./main/neuuni-watch-moderator/neuuni-watch-moderator.module').then(m => m.NeuuniWatchModeratorModule)
    },
    {
        path: 'measurement-indicator',
        loadChildren: () => import('./main/measurement-indicator/measurement-indicator.module').then(m => m.MeasurementIndicatorModule)
    },
    // {
    //     path: 'whatsapp',
    //     loadChildren: () => import('./main/whatsapp/whatsapp.module').then(m => m.WhatsappModule)
    // },
    {
        path: 'migrar',
        loadChildren: () => import('./main/migrar/migrar.module').then(m => m.MigrarModule)
    },
    {
        path: 'resultado-exportar',
        loadChildren: () => import('./main/resultado-exportar/resultado-exportar.module').then(m => m.ResultadoExportarModule)
    },
    {
        path: 'credential',
        loadChildren: () => import('./public-credential/credential.module').then(m => m.CredentialModule)
    },
];

@NgModule({
    imports: [
        // RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy', preloadingStrategy: PreloadAllModules})
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) // TO DEV
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
