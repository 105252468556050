export const locale = {
    lang: 'es',
    data: {
        NAV: {
            HOME: 'inicio',
            MYCOURSES: 'Mis Cursos',
            MYCERTIFICATES: 'Mis Certificados',
            MESSAGES: 'Mensajes',
            COURSES: 'Cursos',
            COURSE: 'Curso',
            CATEGORIES: 'Categorías',
            LANGUAGES: 'Idiomas',
            CURRENCIES: 'Monedas',
            GENERAL: 'General',
            ADMIN: 'Admin',
            ENABLE_EDITION: 'Activar Edición',
            SETTINGS: 'Ajustes',
            INSTITUTION: 'Instituciones',
            ROLES: 'Roles',
            USERS: 'Usuarios',
            CONTACTS: 'Contactos',
            WELCOMECONTENT: 'Contenido de inicio',
            RRHH: 'Recursos humanos',
            LEGALDOCUMENTS: 'Documentos legales',
            OURCOURSES: 'Diferenciadores',
            GENERALINFO: 'Información General',
            TEACHERSMANAGEMENT: 'Gestión de mentores',
            GENERALCONFIG: 'Configuración General',
            DEAN: 'Rector',
            VIDEO: 'Video',
            SLIDERS: 'Portadas',
            SECTIONS: 'Secciones',
            TESTMAIL: 'Correo de prueba',
            WHATSAPP: 'Envío desde Whatsapp',
            ADS: 'Avisos',
            GRADES: 'Calificaciones',
            university: 'Universidad',
            procedure: 'Trámite',
            school_control: 'Control escolar',
            indicators: 'Indicadores',
            STUDYPLAN: 'Planes de estudio',
            CAREER: 'Carreras',
            DISCIPLINE: 'Disciplinas',
            LEVEL: 'Niveles',
            STAGE: 'Estado',
            SUBJECT: 'Asignaturas',
            SALES: 'Ventas',
            COUPONS: 'Cupones',
            ENROLMENTANNOUNCEMENT: 'Generaciones',
            GENERATIONS: 'Generaciones',
            GROUP: 'Grupos',
            CAMPAIGN: 'Campañas',
            COURSEANNOUNCEMENT: 'Carga académica',
            preenrolment: 'Prematrículas',
            mypreenrolments: 'Mis prematrículas',
            offers: 'Ofertas académicas',
            coffeeshop: 'Neuuspace',
            enrolment: 'Matrículas',
            enrolmentFail: 'Matrículas fallidas',
            incomes: 'Ingresos',
            students: 'Estudiantes',
            debtor: 'Importar deudores',
            courseAccess: 'Acceso a cursos',
            forumAccess: 'Acceso a fórums',
            evaluationAccess: 'Acceso a evaluaciones',
            feedbackAccess: 'Acceso a retroalimentación',
            reports: 'Reportes',
            content: 'Contenido',
            syncLesson: 'Clases sincrónicas',
            neuuniWatch: 'Neuuni Play',
            series: 'Series',
            tendencies: 'Tendencias',
            moreViews: 'Más vistos',
            videos: 'Videos',
            indicator: 'Indicadores',
            indicatorKDUnit: 'Indicador CADE por Tema',
            indicatorKDCourse: 'Indicador CADE por Curso',
            indicatorKDMentor: 'Indicador CADE por Mentor',
            sendWhatsappText: 'Envío de Texto',
            migrar: 'Migrar',
            migrarUsuario: 'Migración de Usuarios',
            resultExport: 'Resultado de Exportar',
        }
    }
};
