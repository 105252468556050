import {CoursePermissions} from '../course/config/course-permissions';
import {NeuuniWatchPermissions} from '../neuuni-watch-moderator/config/neuuni-watch-permissions';
import {IndicatorPermissions} from "../measurement-indicator/config/indicator-permissions";


export const indicatorMenu = {
    id: 'indicator',
    name: 'indicator',
    fieldI18Key: 'NAV.indicator',
    menu: [
        {
            name: 'indicatorKDUnit',
            fieldI18Key: 'NAV.indicatorKDUnit',
            url: '/measurement-indicator/indicadorkd-unit',
            roles: [IndicatorPermissions.INDICATOR_CADE_VIEW]
        },
        {
            name: 'indicatorKDCourse',
            fieldI18Key: 'NAV.indicatorKDCourse',
            url: '/measurement-indicator/indicadorkd-course',
            roles: [IndicatorPermissions.INDICATOR_CADE_VIEW]
        },
        {
            name: 'indicatorKDMentor',
            fieldI18Key: 'NAV.indicatorKDMentor',
            url: '/measurement-indicator/indicadorkd-mentor',
            roles: [IndicatorPermissions.INDICATOR_CADE_VIEW]
        }
    ]
};

export const indicatorManagerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        indicatorMenu
    ]
};
