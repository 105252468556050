import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {CurrencyPipe} from '@angular/common';
import {FTableConfiguration} from './models/f-table-configuration';
import {Column} from '../../models/column';

@Component({
    selector: 'qx-f-table',
    templateUrl: './qx-f-table.component.html',
    styleUrls: ['./qx-f-table.component.scss']
})
export class QxFTableComponent implements AfterViewInit {
    @Input() data: any[];
    @Input() resultLength: number;
    @Input() configuration: FTableConfiguration;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sorter: MatSort;
    @Output() page: EventEmitter<PageEvent>;
    @Output() sort: EventEmitter<Sort>;

    constructor() {
        this.page = new EventEmitter<PageEvent>();
        this.sort = new EventEmitter<Sort>();
    }

    ngAfterViewInit(): void {
        this.paginator.page.subscribe(value => this.page.emit(value));
        this.sorter.sortChange.subscribe(value => this.sort.emit(value));
    }

    displayColumns(): string[] {
        const columns = this.configuration.columns.map(c => c.field);
        if (this.configuration.rowActionButtons && this.configuration.rowActionButtons.length > 0) {
            columns.push('actions');
        }
        return columns;
    }

    getValue(item: any, column: Column): any {
        if (column.displayFn) {
            return column.displayFn(item[column.field], item);
        }
        let property = column.field;
        const arr = property.split('|');
        property = arr[0].trim();
        const pipeName = arr.length > 1 ? arr[1].trim() : null;

        let value = item;
        property.split('.').forEach(prop => value = value ? value[prop] : value);

        if (typeof value === 'boolean') {
            return value ? 'Sí' : 'No';
        }

        if (pipeName === 'currency') {
            const pipe = new CurrencyPipe('en_US');
            return pipe.transform(value);
        }

        return value;
    }

    onRowClick(row: any): void {
        this.configuration.rowClickHandler(row);
    }

    getValueBackground(item: any, row: any): any {
        console.log('Valor row', item);
        let value;
        if (item.type == 'connect'){
            value = row.factorNeuuniConectNivelColor;
        }
        if (item.type == 'activity'){
            value = row.factorActivityNivelColor;
        }
        if (item.type == 'feedback'){
            value = row.factorFeedbackNivelColor;
        }
        if (item.type == 'foro'){
            value = row.factorForumNivelColor;
        }
        if (item.type == 'cade'){
            value = row.indicatorCadeNivelColor;
        }
        console.log('Valor AAAAAAAAAA', row);
        return value;
    }
}

