export class CoursePermissions {
    static readonly COURSE_VIEW = 'ROLE_COURSE_VIEW';
    static readonly COURSE_VIEW_MANAGER = 'ROLE_COURSE_VIEW_MANAGER';
    static readonly COURSE_VIEW_SUPERVISOR = 'ROLE_COURSE_VIEW_SUPERVISOR';
    static readonly COURSE_CREATE = 'ROLE_COURSE_CREATE';
    static readonly COURSE_CONTENT_UPDATE = 'ROLE_COURSE_CONTENT_UPDATE';
    static readonly COURSE_UPDATE = 'ROLE_COURSE_UPDATE';
    static readonly COURSE_DELETE = 'ROLE_COURSE_DELETE';

    static readonly UNIT_CREATE         = 'ROLE_UNIT_CREATE';
    static readonly UNIT_UPDATE         = 'ROLE_UNIT_UPDATE';
    static readonly UNIT_DELETE         = 'ROLE_UNIT_DELETE';
    static readonly UNIT_CHANGE_ORDER   = 'ROLE_UNIT_CHANGE_ORDER';

    static readonly UNIT_PROJECT_ORIENTATION_VIEW          = 'ROLE_UNIT_PROJECT_ORIENTATION_VIEW';
    static readonly UNIT_PROJECT_ORIENTATION_CREATE        = 'ROLE_UNIT_PROJECT_ORIENTATION_CREATE';
    static readonly UNIT_PROJECT_ORIENTATION_DELETE        = 'ROLE_UNIT_PROJECT_ORIENTATION_DELETE';
    static readonly UNIT_PROJECT_ORIENTATION_SUMMARY_VIEW  = 'ROLE_UNIT_PROJECT_ORIENTATION_SUMMARY_VIEW';

    static readonly UNIT_FORUM_VIEW     = 'ROLE_UNIT_FORUM_VIEW';
    static readonly UNIT_FORUM_CREATE   = 'ROLE_UNIT_FORUM_CREATE';
    static readonly UNIT_FORUM_INTRO_CREATE_OR_EDIT = 'ROLE_UNIT_FORUM_INTRO_CREATE_OR_EDIT';

    static readonly LESSON_VIEW = 'ROLE_LESSON_VIEW';
    static readonly LESSON_CREATE = 'ROLE_LESSON_CREATE';
    static readonly LESSON_UPDATE = 'ROLE_LESSON_UPDATE';
    static readonly LESSON_DELETE = 'ROLE_LESSON_DELETE';
    static readonly LESSON_CHANGE_ORDER = 'ROLE_LESSON_CHANGE_ORDER';
    static readonly LESSON_UPDATE_CONTENT = 'ROLE_LESSON_UPDATE_CONTENT';
    static readonly LESSON_COMPLETE = 'ROLE_LESSON_COMPLETE';

    static readonly SYNC_LESSON_VIEW = 'ROLE_SYNC_LESSON_VIEW';
    static readonly SYNC_LESSON_CREATE = 'ROLE_SYNC_LESSON_CREATE';
    static readonly SYNC_LESSON_UPDATE = 'ROLE_SYNC_LESSON_UPDATE';
    static readonly SYNC_LESSON_DELETE = 'ROLE_SYNC_LESSON_DELETE';
    static readonly SYNC_LESSON_UPDATE_CONTENT = 'ROLE_SYNC_LESSON_UPDATE_CONTENT';

    static readonly CATEGORY_VIEW = 'ROLE_CATEGORY_VIEW';
    static readonly CATEGORY_CREATE = 'ROLE_CATEGORY_CREATE';
    static readonly CATEGORY_UPDATE = 'ROLE_CATEGORY_UPDATE';
    static readonly CATEGORY_DELETE = 'ROLE_CATEGORY_DELETE';

    static readonly SUBCATEGORY_VIEW = 'ROLE_SUBCATEGORY_VIEW';
    static readonly SUBCATEGORY_CREATE = 'ROLE_SUBCATEGORY_CREATE';
    static readonly SUBCATEGORY_UPDATE = 'ROLE_SUBCATEGORY_UPDATE';
    static readonly SUBCATEGORY_DELETE = 'ROLE_SUBCATEGORY_DELETE';

    static readonly COURSE_MEMBERS_VIEW = 'ROLE_COURSE_MEMBERS_VIEW';
    static readonly COURSE_MEMBERS_CREATE = 'ROLE_COURSE_MEMBERS_CREATE';
    static readonly COURSE_MEMBERS_DELETE = 'ROLE_COURSE_MEMBERS_DELETE';

    static readonly INSTITUTION_VIEW = 'ROLE_INSTITUTION_VIEW';

    static readonly STUDENT = 'ROLE_STUDENT';
    static readonly TEACHER = 'ROLE_TEACHER';

    static readonly UNIT_SUPPORT_CREATE_OR_EDIT = 'ROLE_UNIT_SUPPORT_CREATE_OR_EDIT';
    static readonly UNIT_ROLE_UNIT_SUPPORT_VIEW = 'ROLE_UNIT_SUPPORT_VIEW';

    static readonly BIBLIOGRAPHY_CREATE_OR_EDIT = 'ROLE_BIBLIOGRAPHY_CREATE_OR_EDIT';

    static readonly CONTACT_US_VIEW = 'ROLE_CONTACT_US_VIEW';


    static readonly FOLLOW_USER = 'ROLE_FOLLOW_USER';
    static readonly URL_SEO_MANAGE = 'ROLE_URL_SEO_MANAGE';

    static readonly MANAGE_SIGN_TEACHER_PROFILE = 'ROLE_MANAGE_SIGN_TEACHER_PROFILE';
    static readonly TEACHER_PRINCIPAL_UPDATE = 'ROLE_TEACHER_PRINCIPAL_UPDATE';
    static readonly DEAN_UPDATE = 'ROLE_DEAN_INFO_UPDATE';

    static readonly COUPON_VIEW = 'ROLE_COUPON_VIEW';
    static readonly COUPON_VIEW_ALL = 'ROLE_COUPON_VIEW_ALL';
    static readonly COUPON_CREATE = 'ROLE_COUPON_CREATE';
    static readonly COUPON_UPDATE = 'ROLE_COUPON_UPDATE';
    static readonly COUPON_DELETE = 'ROLE_COUPON_DELETE';
    static readonly COUPON_ASSOCIATE_COURSES = 'ROLE_COUPON_ASSOCIATE_COURSES';

    static readonly UNIVERSITY_STUDENT_MENU_VIEW = 'ROLE_UNIVERSITY_STUDENT_MENU_VIEW';

    static readonly REPORT_ACCESS_REVIEW_BY_TEACHER = 'ROLE_REPORT_ACCESS_REVIEW_BY_TEACHER';

    static readonly VIEW_RESUME_ACTIVITIES = 'ROLE_VIEW_RESUME_ACTIVITIES';
    static readonly CREATE_TOPIC_POST = 'ROLE_TOPIC_POST_CREATE_POST';

    static readonly CONTENT_SYNC_LESSON_MANAGE = 'ROLE_CONTENT_SYNC_LESSON_MANAGE';
    static readonly CONTENT_SYNC_LESSON_MEDIA_CREATOR = 'ROLE_CONTENT_SYNC_LESSON_MEDIA_CREATOR';

    static readonly ACCREDIT_SUBJECT_BY_TEACHER = 'ROLE_ACCREDIT_SUBJECT_BY_TEACHER';

}
