import {Injectable} from '@angular/core';
import {RestClientService} from '../../core/services/rest-client.service';
import {Observable, of} from 'rxjs';
import {User} from '../../core/models/user';
import {Deleteable, Retrieveable, Saveable, Searchable} from '../../core/services/remote-services-interfaces';
import {PagedResult} from '../../course/models/paged-result';


@Injectable({
    providedIn: 'root'
})
export class UserRemoteService implements Searchable<User>,
    Retrieveable<User, string>,
    Saveable<User>,
    Deleteable<User, string> {
    private URL = 'api/users';

    constructor(private restClientService: RestClientService) {
    }

    search(criteria?: any): Observable<PagedResult<User>> {
        criteria.sortColumn = 'lastAccess';
        criteria.order = -1;
        return this.restClientService.get(this.URL, criteria);
    }

    get(id: string): Observable<User> {
        return this.restClientService.get(`${this.URL}/${id}`);
    }

    getCredential(studyPlanId?: any): Observable<any> {
        return this.restClientService.get(`${this.URL}/credential`, {studyPlanId: studyPlanId});
    }

    save(item: User): Observable<User> {
        if (item.id) {
            return this.restClientService.put(`${this.URL}/${item.id}`, item);
        }
        return this.restClientService.post(this.URL, item);
    }

    delete(id: string): Observable<User> {
        return this.restClientService.delete(`${this.URL}/${id}`);
    }

    searchNotInRole(roleId): Observable<User[]> {
        return this.restClientService.get(`${this.URL}/not-in-role/${roleId}`);
    }

    changePassword(data: any): Observable<any> {
        return this.restClientService.put(`${this.URL}/password`, data);
    }

    checkVerification(): Observable<any> {
        return this.restClientService.get(`${this.URL}/check-email-confirmation`);
    }

    saveUniversity(item: User): Observable<any> {
        return this.restClientService.post(this.URL + '/from-university', item);
    }

    sendMailNewUser(userId): Observable<any> {
        return this.restClientService.post(`${this.URL}/send-email-new-account/${userId}`);
    }

    getGoogleInstConfig(): Observable<any> {
        return this.restClientService.get(`${this.URL}/google-inst-domain`);
    }

    openCredential(path: string, filename?: any): void {
        this.restClientService.getBlob(path, {responseType: 'blob'}).subscribe((res: any) => {
            const blob = new Blob([res], {type: 'application/pdf'});
            this.openAS(blob, filename);
        });
    }

    openAS(blob: Blob, filename): void {
        const objUrl = window.URL.createObjectURL(blob);
        const linkElement = document.createElement('a');
        linkElement.href = objUrl;
        //  linkElement.download = filename;
        linkElement.setAttribute('style', 'display: none');

        linkElement.setAttribute('target', '_blank');

        document.body.appendChild(linkElement);
        linkElement.click();
    }

    migrarUserCognito(userId): Observable<any> {
        return this.restClientService.get(`${this.URL}/migrar-user-cognito/${userId}`);
    }

    getCredentialPublic(id?: any): Observable<any> {
        return this.restClientService.get(`${this.URL}/credential-by-enrollment`, {enrollmentId: id});
    }
}
